import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/working_dir/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Playground, Props } from 'docz';
import Component from 'react-component-component';
import { ProgressBar, Button } from '../../src';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "progressbar"
    }}>{`ProgressBar`}</h1>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`import ProgressBar from '@mfrm/mfcl/ProgressBar'
`}</code></pre>
    <p>{`Loaders help the user feel like things are happening`}</p>
    <h2 {...{
      "id": "properties"
    }}>{`Properties`}</h2>
    <ul>
      <li parentName="ul">{`Pass down any props to the ProgressBar Div`}
        <Props of={ProgressBar} mdxType="Props" />
      </li>
    </ul>
    <h2 {...{
      "id": "basic-usage"
    }}>{`Basic usage`}</h2>
    <Playground __position={0} __code={'<ProgressBar value={32} />'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Component,
      ProgressBar,
      Button,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <ProgressBar value={32} mdxType="ProgressBar" />
    </Playground>
    <h2 {...{
      "id": "interactive-example"
    }}>{`Interactive Example`}</h2>
    <Playground __position={1} __code={'<Component initialState={{ value: 1 }}>\n  {({ setState, state }) => (\n    <>\n      <ProgressBar value={state.value} max={15} />\n      <br />\n      <Button\n        onClick={() => {\n          let newValue = state.value + 1\n          if (newValue >= 16) {\n            newValue = 0\n          }\n          setState({ value: newValue })\n        }}\n      >\n        Increment\n      </Button>\n    </>\n  )}\n</Component>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Component,
      ProgressBar,
      Button,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Component initialState={{
        value: 1
      }} mdxType="Component">
    {({
          setState,
          state
        }) => <>
        <ProgressBar value={state.value} max={15} mdxType="ProgressBar" />
        <br />
        <Button onClick={() => {
            let newValue = state.value + 1;
            if (newValue >= 16) {
              newValue = 0;
            }
            setState({
              value: newValue
            });
          }} mdxType="Button">
          Increment
        </Button>
      </>}
  </Component>
    </Playground>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      